import cn from "classnames";
import { useController } from "react-hook-form";
import { InputField } from "../InputField/InputField";
import { useIntl } from "react-intl";
import './AmountInput.scss';

export const AmountInput = (props) => {
    const {field, formState} = useController(props);
    const intl = useIntl();
    const placeholder = intl.formatMessage({
        id: 'page.finance.deposit.popup.field.amount.title',
        defaultMessage: 'Amount',
    });

    const handleChange = (e) => {
        if (Number(e.target.value) <= 0) {
            e.target.value = '';
        }

        e.target.value = e.target.value.replace(/^0+/, '');
        field.onChange(e);
        props?.onChange?.(e)
    };

    const fieldError = formState.errors?.[field.name]?.message || '';

    return (
        <InputField
            id={props.id}
            label={props.label}
            error={fieldError}
            tooltip={props.tooltip}
            className={cn('amount-input', props.className, {required: props.rules?.required})}
        >
            <div className="amount-input-row _flex _flex-a-c _overflow-h">
                <div className="amount-input-wrap _pos-r">
                    <div className="amount-input-currency _flex _flex-col _flex-xy-c">{props.currency || 'USD'}</div>
                    <input
                        {...field}
                        id={props.id}
                        className='input'
                        type={'number'}
                        autoComplete={props.autoComplete ? props.autoComplete : ''}
                        placeholder={props.placeholder || placeholder}
                        value={field.value}
                        min={0}
                        onChange={handleChange}
                        disabled={props.disabled}
                    />
                    <div className='counters _flex _flex-col'>
                        <i className='increase fi fi-rr-angle-small-up'
                           onClick={() => {
                               props.setValue(field.name, Number(field.value || 0) + 1);
                               props?.onChange?.(Number(field.value || 0) + 1);
                           }}
                        />
                        <i className='decrease fi fi-rr-angle-small-down'
                           onClick={() => {
                               if (field.value > 0) {
                                   props.setValue(field.name, Number(field.value) - 1);
                                   props?.onChange?.(Number(field.value) - 1);

                               }
                           }}/>
                    </div>
                </div>
                <div className="amount-input-buttons _flex">
                    {(props.buttons || []).map((v) => (
                        <div
                            key={v}
                            className={cn("amount-button", { active: v === field.value, disabled: props.disabled })}
                            onClick={() => {
                                props.setValue(field.name, v);
                                props?.onChange?.(v)
                            }}
                        >{v}</div>
                    ))}
                </div>
            </div>
            {props.details && <div className="details">{props.details}</div>}
        </InputField>
    );
}